<template>
  <div class="vh-cate">
    <van-field readonly :required="required" input-align="right" is-link
               :rules="required ? [{ required: true}] : []"
               name="vhCateTxt"
               @focus="show = true" v-model="txt" label="车辆类别"
               placeholder="请选择车辆类别" />
    <van-popup v-model:show="show" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="onCancel"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
  export default {
    name: "vh-cate",
    props: ['required', 'value'],
    data () {
      return {
        show: false,
        columns: [
          {
            text: '月租车',
            id: 1
          },
          // {
          //   text: '临时车',
          //   id: 2,
          // },
          {
            text: '储值车',
            id: 3
          },
          {
            text: '分组车',
            id: 4
          }
        ]
        // ,
        // txt: ''
      }
    },
    computed: {
      txt: {
        set() {

        },
        get() {
          console.log('this.value vh_cate', this.value)
          if (this.value) {
            return this.setDefault (this.value)
          } else {
            return this.setDefault (1)
          }
        }
      }
    },
    methods: {
      onConfirm (values) {
        console.log(values)
        // this.txt = values.text
        console.log('console.log(values)', values.id)
        this.$emit('update:value', values.id)
        this.show = false
      },
      onCancel (values) {
        console.log(values)
        // this.setDefault (1)
        this.show = false
      },
      setDefault (val) {
        let txt = ''
        this.columns.forEach((item) => {
          if (val == item.id) {
            txt = item.text
            this.$emit('update:value', item.id)
          }
        })
        return txt
      }
    },
    mounted () {
      this.setDefault (1)
    }
  }
</script>

<style scoped lang="scss">
@import "../theme/index";
.vh-cate{
  display: flex;
  font-size: $global-font-size-X-large;
  font-weight: bold;
  align-items: center;
  justify-content: flex-start;
  color: $global-theme-black;
}
</style>