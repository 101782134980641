<template>
  <div class="add-vh">
    <div class="add-vh-main">
      <park-header></park-header>
      <div class="add-vh-content">
        <!--<page-title :title="'新增固定车辆'"></page-title>-->
        <go-back-title :title="'新增固定车辆'"></go-back-title>
        <van-form @submit="onSubmit">
          <plate-number :required=true :value.sync="form.plateNumber"></plate-number>
          <vh-cate :required=true :value.sync="form.vhCateId"></vh-cate>
          <vh-type :required=true :value.sync="form.vhTypeId"></vh-type>
          <van-field label="分组名称" name="groupName"
                     v-if="form.vhCateId == vhCate.group"
                     v-model="form.groupName"
                     :required="form.vhCateId == vhCate.group"
                     :rules="[{ required: form.vhCateId == vhCate.group, validator: validateGroupName, message: '请输入分组名称'}]"
                     placeholder="分组名称" />
          <div class="expired-wrap">
            <div class="expired-title">请选择注册车辆有效期</div>
            <div class="expired-bnt-wrap">
              <div :class="['expire-item', form.forever ? 'active' : '']" @click="changeForever">永久有效</div>
              <div :class="['expire-item', !form.forever ? 'active' : '']" @click="changeForever">短期有效</div>
            </div>
          </div>
          <template v-if="!form.forever">
            <van-cell value="按月充值(月)">
              <template #right-icon>
                <van-stepper v-model="form.num" theme="round" button-size="22"
                             default-value=1
                             min=1
                             @change="getExpireTimeRange"
                             disable-input />
              </template>
            </van-cell>
            <van-field label="有效时间" name="expireTimeRange" is-link
                       readonly v-model="form.expireTimeRange"
                       :rules="[]"
                       placeholder="" />
          </template>
          <van-cell value="更多信息录入" @click.native="showMore">
            <template #extra>
              <div :class="['more-img', isMore ? 'up' : 'down']"></div>
            </template>
          </van-cell>
          <template v-if="isMore">
            <van-field :rules="[]" name="monthlyRentFee" label="月租金" v-model="form.monthlyRentFee" placeholder="" />
            <van-field :rules="[]" name="guaranteeDepositFee" label="押金" v-model="form.guaranteeDepositFee" placeholder="" />
            <van-field :rules="[]" name="ownerName" label="车主姓名" v-model="form.ownerName" placeholder="" />
            <van-field :rules="[]" name="ownerPhone" label="联系方式" v-model="form.ownerPhone" placeholder="" />
          </template>
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">提交</van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import parkHeader from '../../public/park-header'
// import pageTitle from '../../public/page-title'
import vhCate from '../../public/vh-cate'
import vhType from '../../public/vh-type'
import plateNumber from '../../public/plate-number'
import * as common from '../../api/common'
import * as api from '../../api/api'
import * as mqtt from '../../api/wsconnect.js'
import goBackTitle from '../../public/go-back-title'
// import * as util from '../../api/util.js'
import { mapState } from 'vuex'
export default {
  name: 'add-vh',
  components: {
    parkHeader,
    // pageTitle,
    goBackTitle,
    vhCate,
    vhType,
    plateNumber
  },
  data () {
    return {
      show: false,
      isMore: false,
      isLoading: false,
      vhCate: common.vh_cate,
      pattern: new RegExp('^[' +  common.plateNumberHeader.join('') + '][A-Z][A-Z0-9]{5,6}$'),
      form: {
        plateNumber: '',
        chargeFee: 0,
        num: 1,
        vhCateId: '',
        vhTypeId: '',
        monthlyRentFee: 0,
        expireTimeRange: '',
        ownerName: '',
        guaranteeDepositFee: 0,
        ownerPhone: '',
        forever: false,
        groupName: ''
      },
      startTime: new Date(),
      endTime: new Date()
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  methods: {
    validateGroupName() {
      if (this.form.vhCateId == common.vh_cate.group){
        return !!this.form.groupName
      } else {
        return true
      }
    },
    onMessage (message) {
      console.log('message', message)
      if (message && message.code == common.resCode.OK) {
        this.$nextTick(() => {
          this.form.vhCateId = 1
          this.form.vhTypeId = 1
          this.form.plateNumber = ''
          this.form.monthlyRentFee = 0
          this.form.ownerName = ''
          this.form.guaranteeDepositFee = 0
          this.form.ownerPhone = ''
        })
        this.$toast.success('添加成功')
      } else if (message && message.code == common.resCode.Failed) {
        this.$toast.fail(message.msg)
      } else {
        this.$toast.fail('添加失败')
      }
    },
    async onSubmit (form) {
      try {
        let para = {
          plate_number: form.plateNumber,
          vh_type_id: this.form.vhTypeId,
          vh_cate_id: this.form.vhCateId,
          // customer_id: localStorage.getItem("customer_id"),
          sid: localStorage.getItem('sid'),
          monthly_rent_fee: Number(form.monthlyRentFee)*100,
          owner_name: form.ownerName,
          guarantee_deposit_fee: Number(form.guaranteeDepositFee)*100,
          owner_phone: form.ownerPhone,
          ...api.getPublicPara('v2')
        }
        para.effective_date_start = this.startTime
        para.effective_date_end = this.endTime
        para.charged_month_count = this.form.num
        if (this.form.vhCateId == common.vh_cate.group) {
          para.group_name = this.form.groupName
        }
        if (!para.access_token) {
          this.$router.push({
            path: '/login',
            query: {
              ...api.getQuery.apply(this)
            }
          })
          return
        }
        this.$toast.loading('正在请求中...')
        let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.add_vh}`;
        await mqtt.subscribe(topic)
        this.isLoading = true
        mqtt.Client.config[mqtt.topics.add_vh] = this.onMessage
        let res = await api.axiosRequest(api.urlList.fenglin.vh.add, para)
        this.$toast.clear()
        this.isLoading = false
        if (res.code != common.resCode.OK) return this.$toast(res.msg || '请求出错')
      } catch (e) {
        common.showErrorMessage(this, e)
      }
    },
    showMore () {
      this.isMore = !this.isMore
    },
    changeForever () {
      this.form.forever = !this.form.forever
      this.getExpireTimeRange ()

    },
    getExpireTimeRange (val) {
      console.log('val', val)

      let now = new Date()
      let startDate = common.fmtDate(now, 'yyyy-MM-dd')
      let endDate
      this.startTime = startDate + ' 00:00:00'
      if (this.form.forever) {
        endDate = '2999-01-01'
        this.endTime = endDate + ' 23:59:59'
        this.form.expireTimeRange = '永久'
      } else {
        let nowMonth = now.getMonth()
        now.setMonth(nowMonth + this.form.num)
        endDate = common.fmtDate(new Date(now), 'yyyy-MM-dd')
        this.endTime = endDate + ' 23:59:59'
        this.form.expireTimeRange = startDate + '~' + endDate
      }
    }
  },

  mounted () {
    new mqtt.Client()
    this.getExpireTimeRange ()
  },
  beforeDestroy () {
    let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.add_vh}`
    mqtt.unsubscribe(topic).catch(e => console.log(e))
    delete mqtt.Client.config[mqtt.topics.add_vh]
  }
}
</script>

<style scoped lang="scss">
@import "../../theme/index";
.add-vh {
  display: flex;
  @extend .w-100-h-100;
  /*<!--background-color: $global-theme-bg-blue;;-->*/
  &-main {
    position: relative;
    @extend .content-wrap-gap;
    width: 100%;
    .add-vh-content {
      box-sizing: border-box;
      margin: 1rem 1rem 0 1rem;

      .expired-wrap{
        margin: 1rem;

        .expired-title{
          margin-bottom: 1rem;
          text-align: left;
          font-size: $global-font-size-small;
          color: $global-theme-gray;
        }
        .expired-bnt-wrap{
          display: flex;

          .expire-item{
            margin-right: 1rem;
            width: 11rem;
            height: 4.8rem;
            line-height: 4.8rem;
            background: #FAFAFA;
            border-radius: .4rem;
            border: .2rem solid #CCCCCC;
          }
          .expire-item.active {
            color: $global-theme-blue;
            border: .2rem solid $global-theme-blue;
            background: $global-theme-bg-blue;
          }
        }
      }

    }
    .more-img{
      width: 2rem;
      height: 2rem;
      &.up{
        background-image: url("../../assets/up.png");
        background-size: 100% 100%;
      }
      &.down{
        background-image: url("../../assets/down.png");
        background-size: 100% 100%;
      }
    }
  }
  /deep/ .van-stepper--round .van-stepper__minus {
    color: white;
    background-color:$global-theme-blue;
    border: .2rem solid $global-theme-blue;
  }
  /deep/ .van-stepper--round .van-stepper__plus {
    color: #fff;
    background-color: $global-theme-blue;
  }
}
</style>
